import React, { useState } from 'react';
import { encode } from '../utils';

const EmploymentForm = () => {
  const [formState, setFormState] = useState({});
  const [formError, setFormError] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleChange = (e) => {
    const getValue = () => {
      if (e.target.name.indexOf('[]') > 0) {
        const currentValue = formState[e.target.name] ? formState[e.target.name].split(',') : []
        if (e.target.checked && currentValue.indexOf(e.target.value) < 0) {
          currentValue.push(e.target.value)
          return currentValue.join(',')
        } else if (!e.target.checked) {
          return currentValue.filter(val => val !== e.target.value).join(',')
        }
      }
      return e.target.value
    }
    setFormState({ ...formState, [e.target.name]: getValue() });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const body = encode({
      'form-name': form.getAttribute('name'),
      ...formState,
    })
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body,
    })
      .then(() => setFormSubmitted(true))
      .catch(error => setFormError(error.message));
  };

  const { qualification } = formState
  const showFieldset = qualification && ((qualification === 'CNA' && formState['cna-license'] === 'yes') || (qualification !== 'no' && qualification !== 'CNA'))

  return (
    <>
      {formSubmitted && (
        <>
          <h2>Thank you for contacting DanielCare, LLC!</h2>
          <p>We appreciate your time in completing the online employment inquiry. Once we have a position to offer, we will contact you with an appointment date and time.</p>
        </>
      )}
      {!formSubmitted && (
        <form
          name="application-for-employment"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          {formError && (
            <div
              style={{
                backgroundColor: 'red',
                color: 'white',
                padding: '1em',
                marginBottom: '1em',
              }}
            >
              {formError}
            </div>
          )}
          <input
            name="bot-field"
            onChange={handleChange}
            style={{
              display: 'none',
            }}
          />
          <input type="hidden" name="form-name" value="application-for-employment" />

          <fieldset>
            <ol>
              <li className="inline follow-up disqualifier qualifications">
                <fieldset>
                  <legend>Please select your qualification:</legend>
                  <label><input type="radio" name="qualification" value="CNA" onChange={handleChange} />CNA</label>
                  <label><input type="radio" name="qualification" value="LPN" onChange={handleChange} />LPN</label>
                  <label><input type="radio" name="qualification" value="RN" onChange={handleChange} />RN</label>
                  <label><input type="radio" name="qualification" value="PT" onChange={handleChange} />PT</label>
                  <label><input type="radio" name="qualification" value="OT" onChange={handleChange} />OT</label>
                  <label><input type="radio" name="qualification" value="Speech Therapist" onChange={handleChange} />Speech Therapist</label>
                  <label><input type="radio" name="qualification" value="MSW" onChange={handleChange} />MSW</label>
                  <label><input type="radio" name="qualification" value="no" onChange={handleChange} />None of the above</label>
                  {formState.qualification === 'no' && (
                    <div className="message" data-displayon="no">
                      Please do not continue with this application.
                    </div>
                  )}
                </fieldset>
              </li>
              <li className={`inline follow-up disqualifier cna-state-license cna-support ${formState.qualification !== 'CNA' ? 'hidden' : ''}`}>
                <fieldset>
                  <legend>Do you have a CNA state license?</legend>
                  <label><input type="radio" name="cna-license" value="yes" onChange={handleChange} />Yes</label>
                  <label><input type="radio" name="cna-license" value="no" onChange={handleChange} />No</label>
                  {formState['cna-license'] === 'no' && (
                    <>
                      <div className="message" data-displayon="no">
                        Please do not continue with this application.
                      </div>
                      <div className="excel">
                        <img src="/assets/images/ExcelAcademyLogo_RBG.png" alt="Excel Academy - A DanielCare Affiliate"/>
                        <p>We are affiliated with a CNA school called Excel Academy with classes being held in both Stamford and New Haven.</p>
                        <p>For more information please log onto: <a href="http://www.excelacademyconn.com" target="_blank">www.excelacademyconn.com</a></p>
                      </div>
                    </>
                  )}
                </fieldset>
              </li>
            </ol>
          </fieldset>
          <fieldset className={`application ${!showFieldset ? 'hidden' : ''}`}>
            <ol>
              <li><label htmlFor="firstName">First name</label><input type="text" name="firstName" maxLength="30" onChange={handleChange} /></li>
              <li><label htmlFor="lastName">Last name</label><input type="text" name="lastName" maxLength="30" onChange={handleChange} /></li>
              <li><label htmlFor="email">Email</label><input type="text" name="email" maxLength="30" onChange={handleChange} /></li>
              <li><label htmlFor="address">Mailing address</label><input type="text" name="address" maxLength="30" onChange={handleChange} /></li>
              <li><label htmlFor="city">City</label><input type="text" name="city" maxLength="30" onChange={handleChange} /></li>
              <li>
                <label htmlFor="state">State/Province</label>
                <select name="state" onChange={handleChange}>
                  <option value="value">Select Your State or Province</option>
                  <option value="AL">Alabama</option> 
                  <option value="AK">Alaska</option> 
                  <option value="AB">Alberta</option>
                  <option value="AZ">Arizona</option> 
                  <option value="AR">Arkansas</option> 
                  <option value="BC">British Columbia</option>
                  <option value="CA">California</option> 
                  <option value="CO">Colorado</option> 
                  <option value="CT">Connecticut</option> 
                  <option value="DE">Delaware</option> 
                  <option value="DC">District Of Columbia</option> 
                  <option value="FL">Florida</option> 
                  <option value="GA">Georgia</option> 
                  <option value="HI">Hawaii</option> 
                  <option value="ID">Idaho</option> 
                  <option value="IL">Illinois</option> 
                  <option value="IN">Indiana</option> 
                  <option value="IA">Iowa</option> 
                  <option value="KS">Kansas</option> 
                  <option value="KY">Kentucky</option> 
                  <option value="LA">Louisiana</option> 
                  <option value="ME">Maine</option> 
                  <option value="MB">Manitoba</option>
                  <option value="MD">Maryland</option> 
                  <option value="MA">Massachusetts</option> 
                  <option value="MI">Michigan</option> 
                  <option value="MN">Minnesota</option> 
                  <option value="MS">Mississippi</option> 
                  <option value="MO">Missouri</option> 
                  <option value="MT">Montana</option> 
                  <option value="NE">Nebraska</option> 
                  <option value="NV">Nevada</option> 
                  <option value="NB">New Brunswick</option>
                  <option value="NH">New Hampshire</option> 
                  <option value="NJ">New Jersey</option> 
                  <option value="NM">New Mexico</option> 
                  <option value="NY">New York</option> 
                  <option value="NL">Newfoundland and Labrador</option>
                  <option value="NC">North Carolina</option> 
                  <option value="ND">North Dakota</option> 
                  <option value="NT">Northwest Territories</option>
                  <option value="NS">Nova Scotia</option>
                  <option value="NU">Nunavut</option>
                  <option value="OH">Ohio</option> 
                  <option value="OK">Oklahoma</option> 
                  <option value="ON">Ontario</option>
                  <option value="OR">Oregon</option> 
                  <option value="PA">Pennsylvania</option> 
                  <option value="PE">Prince Edward Island</option>
                  <option value="QC">Quebec</option>
                  <option value="RI">Rhode Island</option> 
                  <option value="SK">Saskatchewan</option>
                  <option value="SC">South Carolina</option> 
                  <option value="SD">South Dakota</option> 
                  <option value="TN">Tennessee</option> 
                  <option value="TX">Texas</option> 
                  <option value="UT">Utah</option> 
                  <option value="VT">Vermont</option> 
                  <option value="VA">Virginia</option> 
                  <option value="WA">Washington</option> 
                  <option value="WV">West Virginia</option> 
                  <option value="WI">Wisconsin</option> 
                  <option value="WY">Wyoming</option>
                  <option value="YT">Yukon</option>
                </select>
              </li>
              <li><label htmlFor="zip">Zip/Postal</label><input type="text" name="zip" maxLength="10" onChange={handleChange} /></li>
              <li><label htmlFor="phone">Phone</label><input type="text" name="phone" maxLength="25" onChange={handleChange} /></li>
              <li className="inline follow-up reverse cna-support">
                <fieldset>
                  <legend>Is your CNA license from CT?</legend>
                  <label><input type="radio" name="cna-ct-license" value="yes" onChange={handleChange} />Yes</label>
                  <label><input type="radio" name="cna-ct-license" value="no" onChange={handleChange} />No</label>
                </fieldset>
              </li>
              <li className={formState['cna-ct-license'] === 'no' ? '' : 'hidden'}>
                <label htmlFor="cna-state">Please indicate from which state it was issued:</label>
                <select name="cna-state" onChange={handleChange}>
                  <option value="value">Select Your State or Province</option>
                  <option value="AL">Alabama</option> 
                  <option value="AK">Alaska</option> 
                  <option value="AB">Alberta</option>
                  <option value="AZ">Arizona</option> 
                  <option value="AR">Arkansas</option> 
                  <option value="BC">British Columbia</option>
                  <option value="CA">California</option> 
                  <option value="CO">Colorado</option> 
                  <option value="CT">Connecticut</option> 
                  <option value="DE">Delaware</option> 
                  <option value="DC">District Of Columbia</option> 
                  <option value="FL">Florida</option> 
                  <option value="GA">Georgia</option> 
                  <option value="HI">Hawaii</option> 
                  <option value="ID">Idaho</option> 
                  <option value="IL">Illinois</option> 
                  <option value="IN">Indiana</option> 
                  <option value="IA">Iowa</option> 
                  <option value="KS">Kansas</option> 
                  <option value="KY">Kentucky</option> 
                  <option value="LA">Louisiana</option> 
                  <option value="ME">Maine</option> 
                  <option value="MB">Manitoba</option>
                  <option value="MD">Maryland</option> 
                  <option value="MA">Massachusetts</option> 
                  <option value="MI">Michigan</option> 
                  <option value="MN">Minnesota</option> 
                  <option value="MS">Mississippi</option> 
                  <option value="MO">Missouri</option> 
                  <option value="MT">Montana</option> 
                  <option value="NE">Nebraska</option> 
                  <option value="NV">Nevada</option> 
                  <option value="NB">New Brunswick</option>
                  <option value="NH">New Hampshire</option> 
                  <option value="NJ">New Jersey</option> 
                  <option value="NM">New Mexico</option> 
                  <option value="NY">New York</option> 
                  <option value="NL">Newfoundland and Labrador</option>
                  <option value="NC">North Carolina</option> 
                  <option value="ND">North Dakota</option> 
                  <option value="NT">Northwest Territories</option>
                  <option value="NS">Nova Scotia</option>
                  <option value="NU">Nunavut</option>
                  <option value="OH">Ohio</option> 
                  <option value="OK">Oklahoma</option> 
                  <option value="ON">Ontario</option>
                  <option value="OR">Oregon</option> 
                  <option value="PA">Pennsylvania</option> 
                  <option value="PE">Prince Edward Island</option>
                  <option value="QC">Quebec</option>
                  <option value="RI">Rhode Island</option> 
                  <option value="SK">Saskatchewan</option>
                  <option value="SC">South Carolina</option> 
                  <option value="SD">South Dakota</option> 
                  <option value="TN">Tennessee</option> 
                  <option value="TX">Texas</option> 
                  <option value="UT">Utah</option> 
                  <option value="VT">Vermont</option> 
                  <option value="VA">Virginia</option> 
                  <option value="WA">Washington</option> 
                  <option value="WV">West Virginia</option> 
                  <option value="WI">Wisconsin</option> 
                  <option value="WY">Wyoming</option>
                  <option value="YT">Yukon</option>
                </select>
              </li>
              <li className="inline">
                <fieldset>
                  <legend>Do you drive and have a car?</legend>
                  <label><input type="radio" name="drive" value="yes" onChange={handleChange} />Yes</label>
                  <label><input type="radio" name="drive" value="no" onChange={handleChange} />No</label>
                </fieldset>
              </li>
              <li className="inline follow-up">
                <fieldset>
                  <legend>Did you work with another Medicare agency in the past?</legend>
                  <label><input type="radio" name="cna-medicare-agency" value="yes" onChange={handleChange} />Yes</label>
                  <label><input type="radio" name="cna-medicare-agency" value="no" onChange={handleChange} />No</label>
                </fieldset>
              </li>
              <li className={`inline ${formState['cna-medicare-agency'] !== 'yes' ? 'hidden' : ''}`}>
                <fieldset>
                  <legend>Can you get a copy of your 'Competency' certificate?</legend>
                  <label><input type="radio" name="cna-competency-certificate" value="yes" onChange={handleChange} />Yes</label>
                  <label><input type="radio" name="cna-competency-certificate" value="no" onChange={handleChange} />No</label>
                </fieldset>
              </li>
              <li className="inline">
                <fieldset>
                  <legend>Which of our offices would you be willing to work in? (select all that apply)</legend>
                  <label><input type="checkbox" name="offices[]" value="Stamford" onChange={handleChange} />Stamford</label>
                  <label><input type="checkbox" name="offices[]" value="Shelton" onChange={handleChange} />Shelton</label>
                </fieldset>
              </li>
              <li>
                <label htmlFor="referral">How did you hear about DanielCare?</label>
                <select name="referral" onChange={handleChange}>
                  <option></option>
                  <option>From a friend</option>
                  <option>Internet</option>
                  <option>Sign outside our office</option>
                  <option>Other</option>
                </select>
                {/* <textarea className="referral-support hidden" id="referral-other" name="referral-other" placeholder="Describe how" onChange={handleChange}></textarea> */}
              </li>
              {/* <li className="referral-support friend hidden"><label htmlFor="friend-name">Friend's name</label><input type="text" id="friend-name" name="friend-name" onChange={handleChange} /></li> */}
              {/* <li className="referral-support friend hidden"><label htmlFor="friend-phone">Friend's phone number</label><input type="text" id="friend-phone" name="friend-phone" onChange={handleChange} /></li> */}
              <li><label htmlFor="comments" className="textarea">Comments/ Questions</label><textarea name="comments" onChange={handleChange}></textarea></li>
            </ol>
            <p>Thank you very much for your time in filling out this application.</p>
            <input type="submit" value="Submit" className="submit" name="submit" />
          </fieldset>
        </form>
      )}
    </>
  );
};

export default EmploymentForm;
