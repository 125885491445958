import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import EmploymentForm from '../components/employment-form';
import HeaderContent from '../components/header-content';
import Metadata from '../components/metadata';

const Employment = ({ data }) => {
  const { markdownRemark: page } = data;
  const { frontmatter: metadata, html } = page;

  return (
    <Layout
      headerContent={<HeaderContent />}
      className="danielcare"
    >
      <Metadata pageData={metadata} />
      <article>
        <h1>{metadata.pagetitle || metadata.title }</h1>
        <div dangerouslySetInnerHTML={{ __html: html }} />
        <EmploymentForm />
      </article>
    </Layout>
  );
};

Employment.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }).isRequired,
};

export default Employment;

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        pagetitle
      }
    }
  }
`;
